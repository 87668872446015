import React, { useState } from "react";
import { Button, CircularProgress, Alert } from "@mui/material";
import { useTranslation } from "react-i18next";
import * as shapefile from "shapefile";

const FileUploader = () => {
  const { t } = useTranslation();
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [, setFeatures] = useState(null);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    setShowAlert(false);
  };

  const handleUpload = () => {
    if (!file) {
      setAlertMessage(t("shapefile_page.alert_not_file"));
      setShowAlert(true);
      return;
    }

    const fileExtension = file.name.split(".").pop().toLowerCase();
    if (fileExtension !== "shp") {
      setAlertMessage(t("shapefile_page.alert_invalid_file"));
      setShowAlert(true);
      return;
    }

    setIsLoading(true);

    try {
      const fileReader = new FileReader();
      fileReader.onload = async (e) => {
        const arrayBuffer = e.target.result;
        const reader = await shapefile.open(arrayBuffer);
        const features = [];

        let result = await reader.read();
        while (!result.done) {
          features.push(result.value);
          result = await reader.read();
        }

        setFeatures(features);
        sessionStorage.setItem("shapefileData", JSON.stringify(features)); // Salva nella sessione
        setIsLoading(false);
        setShowAlert(true);
        setAlertMessage(t("shapefile_page.upload_success"));
      };

      fileReader.readAsArrayBuffer(file);
    } catch (error) {
      console.error("Errore nel caricamento dello shapefile:", error);
      setIsLoading(false);
      setAlertMessage(t("shapefile_page.upload_error"));
    }
  };

  return (
    <div>
      <input type="file" onChange={handleFileChange} />
      <Button variant="contained" color="primary" onClick={handleUpload}>
        Upload
      </Button>
      {isLoading && <CircularProgress />}
      {showAlert && (
        <Alert
          severity={
            alertMessage === t("shapefile_page.upload_success")
              ? "success"
              : "error"
          }
          onClose={() => setShowAlert(false)}
          sx={{ width: "fit-content" }}
        >
          {alertMessage}
        </Alert>
      )}
    </div>
  );
};

export default FileUploader;
