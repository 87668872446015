import { TableHead } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import DeleteIcon from "@mui/icons-material/Delete";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "5px",
    paddingLeft: "16px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const ListTableWithIdHideAndDelete = (props) => {
  const { t } = useTranslation();
  const tableTitle = props.tableTitle ?? "";
  const showViewAll = props.showViewAll ?? true;
  const [viewAll, setViewAll] = useState(props.viewAll ?? false);
  const isRowDeletable = props?.isRowDeletable;
  const { onRowDeleteClick } = props;

  const rows = props.rows ?? [];

  const getHeaders = (rows) => {
    const lastObj = rows?.length > 0 ? rows[rows?.length - 1] : {};
    const result = Object.keys(lastObj)
      .filter((key) => key !== "id")
      .map((key) => key + addUintMeasure(key));
    return result;
  };

  const addUintMeasure = (key) => {
    let unit = "";

    if (tableTitle === "Irrigation") {
      if (key === "amount") {
        unit = " (l)";
      }
    } else if (tableTitle === "Soil preparation") {
      if (key === "depth") {
        unit = " (cm)";
      }
    } else if (tableTitle === "Fertilizer") {
      if (key === "quantity") {
        unit = " (kg/ha)";
      }
    } else if (tableTitle === "Pesticide") {
      if (key === "quantity") {
        unit = " (kg/ha)";
      }
    }
    return unit;
  };

  return (
    <TableContainer component={Paper}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "0 20px",
        }}
      >
        <h4>{tableTitle}</h4>
        {showViewAll && (
          <div
            onClick={() => setViewAll(!viewAll)}
            style={{ cursor: "pointer" }}
          >
            <h5>{viewAll ? t("view_last") : t("view_all")}</h5>
          </div>
        )}
      </div>
      <Table sx={{ minWidth: 100 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            {rows &&
              getHeaders(rows).map((row, i) => (
                <TableCell key={row}>
                  <div
                    style={{
                      fontSize: "12px",
                      fontWeight: "bold",
                    }}
                  >
                    {row.toUpperCase()}
                  </div>
                </TableCell>
              ))}
            <TableCell key={"del"}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!viewAll && rows && rows[0] && (
            <>
              {
                <StyledTableRow
                  key={rows[0].name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  {Object.keys(rows[0])
                    .filter((key) => key !== "id")
                    .map((key) => (
                      <StyledTableCell
                        key={rows[0][key]}
                        component="th"
                        scope="row"
                      >
                        {checkData(key, rows[0][key])}
                      </StyledTableCell>
                    ))}

                  {isRowDeletable && (
                    <StyledTableCell component="th" scope="row">
                      <div
                        onClick={() => onRowDeleteClick(rows[0])}
                        style={{ cursor: "pointer" }}
                      >
                        <DeleteIcon fontSize="small" />
                      </div>
                    </StyledTableCell>
                  )}
                </StyledTableRow>
              }
            </>
          )}
          {viewAll && rows && (
            <>
              {rows.map((row, i) => (
                <StyledTableRow
                  key={i}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  {Object.keys(row)
                    .filter((key) => key !== "id")
                    .map((key) => (
                      <StyledTableCell
                        key={row[key]}
                        component="th"
                        scope="row"
                      >
                        {checkData(key, row[key])}
                      </StyledTableCell>
                    ))}

                  {isRowDeletable && (
                    <StyledTableCell component="th" scope="row">
                      <div
                        onClick={() => onRowDeleteClick(row)}
                        style={{ cursor: "pointer" }}
                      >
                        <DeleteIcon fontSize="small" />
                      </div>
                    </StyledTableCell>
                  )}
                </StyledTableRow>
              ))}
            </>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ListTableWithIdHideAndDelete;

export const checkData = (key, value) => {
  if (key === "date" || key === "tillage" || key === "plough") {
    value = dayjs(value).format("DD/MM/YYYY");
  }

  if (key === "time" && value) {
    value = value.substring(0, 5);
  }
  return value || "";
};
