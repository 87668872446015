import L from "leaflet";
import { useEffect } from "react";
import "leaflet-control-geocoder/Control.Geocoder.css";
import "leaflet-control-geocoder";
import markerIcon from "leaflet/dist/images/marker-icon.png";
import markerShadow from "leaflet/dist/images/marker-shadow.png";

const DefaultIcon = L.icon({
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

L.Marker.prototype.options.icon = DefaultIcon;

export const AddSearchControl = ({ mapRef }) => {
  useEffect(() => {
    if (!mapRef || !mapRef.current) return;

    const map = mapRef.current;

    const geocoder = L.Control.Geocoder.nominatim(); // Usa il provider Nominatim
    const searchControl = L.Control.geocoder({
      geocoder,
      defaultMarkGeocode: true, // Mostra un marker sul risultato
    })
      .on("markgeocode", function (e) {
        const bbox = e.geocode.bbox;
        const bounds = L.latLngBounds(bbox.getSouthWest(), bbox.getNorthEast());
        map.fitBounds(bounds); // Adatta i limiti della mappa al risultato
      })
      .addTo(map);

    return () => {
      map.removeControl(searchControl);
    };
  }, [mapRef]);

  return null;
};
