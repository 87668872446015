import { Grid } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import MapDrawComponent from "shared-components/src/components/map/mapDraw.component";
import { routerPaths } from "../../router/routerPaths";
import { convertGeoJSONCoordinatesFromShp } from "shared-components/src/components/map/utils";
import { v4 as uuidv4 } from "uuid";

const MapAddUpdateFieldComponent = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { values, setFieldValue } = props?.formikProps;
  const [polygons, setPolygons] = useState(values?.polygons);
  const [shapefileData, setShapefileData] = useState(null);

  useEffect(() => {
    const savedData = sessionStorage.getItem("shapefileData");
    if (savedData) {
      const savedDataParsed = JSON.parse(savedData);
      setShapefileData(convertGeoJSONCoordinatesFromShp(savedDataParsed));
    }
  }, []);

  useEffect(() => {
    if (shapefileData && shapefileData[0]) {
      const firstFeature = shapefileData[0];
      const newCoordinates = firstFeature.geometry.coordinates[0].map(
        ([lat, lng]) => [lng, lat]
      );

      const firstPolygon = {
        id: uuidv4(),
        position: newCoordinates,
        color: "blue",
      };

      setPolygons([firstPolygon]);
      setFieldValue("polygons", [firstPolygon]);

      sessionStorage.removeItem("shapefileData");
    }
  }, [shapefileData, setFieldValue]);

  const handlePolygonsChange = (editedPolygons) => {
    setPolygons(editedPolygons);
    setFieldValue("polygons", editedPolygons);
  };

  return (
    <Fragment>
      <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12} sm={12} md={12}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div>{t("map_add_update.div_draw")}</div>
            <div
              onClick={() => navigate(routerPaths.shapefile)}
              style={{ textDecoration: "underline", cursor: "pointer" }}
            >
              {t("map_add_update.div_add")}
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <MapDrawComponent
            polygons={polygons}
            onPolygonsChange={handlePolygonsChange}
          ></MapDrawComponent>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default MapAddUpdateFieldComponent;
