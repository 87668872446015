import "leaflet/dist/leaflet.css";
import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  GeoJSON,
  LayersControl,
  MapContainer,
  Marker,
  Polygon,
  Popup,
  SVGOverlay,
  TileLayer,
  Tooltip,
} from "react-leaflet";

import DOMPurify from "dompurify";
import L from "leaflet";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { routerPaths } from "../../../../acquaount-dashboard-farm/src/router/routerPaths";
import { setField } from "../../../../acquaount-dashboard-farm/src/store/actions/field.actions";
import CustomizedTables from "../displayData/CustomizedTables.component";
import createCustomIcon from "./createCustomIcon";

const MapComponent = (props) => {
  const mapRef = useRef(null);
  const [geojsonData, setGeojsonData] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const mapHeight = props.mapHeight ?? "400px";
  const mapZoom = props.mapZoom ?? 7;
  const mapCenter = props.mapCenter ?? [40.356862371325384, 9.240084014976134];
  const mapUrl =
    props.mapUrl ?? "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
  const attribution =
    props.attribution ??
    '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> | Abinsula';
  const polygons = props.polygons ?? null;
  const layers = props.layers ?? null;
  const markersWithNumber = props.markersWithNumber ?? null;

  useEffect(() => {
    const map = mapRef.current;

    if (map) {
      if (map._layers) {
        const allLayerBounds = Object.values(map._layers).map(
          (layer) => layer._bounds
        );
        const allBounds = L.latLngBounds(allLayerBounds);
        if (allBounds.isValid()) {
          map.fitBounds(allBounds);
        }
      }
    }
  }, [mapRef.current, polygons, layers]);

  const sanitizedHTML = (value) => {
    return DOMPurify.sanitize(value);
  };

  const handlerLayer = (fieldId) => {
    dispatch(setField(fieldId));
    navigate(routerPaths.realtime);
  };

  return (
    <MapContainer
      center={mapCenter}
      zoom={mapZoom}
      style={{ height: mapHeight }}
      ref={mapRef}
    >
      <LayersControl position="topright">
        <LayersControl.BaseLayer checked name="ArcGIS">
          <TileLayer
            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
            attribution='&copy; <a href="https://www.esri.com">Esri</a> | Abinsula'
          />
        </LayersControl.BaseLayer>

        <LayersControl.BaseLayer name="OpenStreetMap">
          <TileLayer url={mapUrl} attribution={attribution} />
        </LayersControl.BaseLayer>

        <LayersControl.BaseLayer name="Opentopomap">
          <TileLayer
            url="https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.opentopomap.org/copyright">OpenTopoMap</a> | Abinsula'
          />
        </LayersControl.BaseLayer>
      </LayersControl>

      {geojsonData && <GeoJSON data={geojsonData} />}

      {polygons &&
        polygons.map((polygon) => (
          <Polygon
            key={polygon.id}
            positions={polygon.position}
            color={polygon.color}
          >
            {polygon.popup && <Popup>{polygon.popup}</Popup>}
            {polygon.tooltip && <Tooltip>{polygon.tooltip}</Tooltip>}
          </Polygon>
        ))}

      {layers &&
        layers
          .filter((layer) => layer?.bounds && layer.bounds.length > 0)
          .map((layer) => (
            <Fragment key={layer?.id}>
              {/* <ImageOverlayComponent key={layer.id} imageUrl={layer?.imageUrl} bounds={layer?.bounds} checkColor={layer?.color} field={layer?.field} /> */}

              <Polygon
                key={layer?.id}
                positions={layer?.bounds}
                color={layer?.color}
              >
                <Popup>
                  <div
                    style={{ cursor: "pointer", fontWeight: "bold" }}
                    onClick={() => handlerLayer(layer?.field.id)}
                  >
                    {layer?.field.name}
                  </div>
                </Popup>
              </Polygon>
              {layer?.bounds.length > 0 && (
                <SVGOverlay
                  key={"cir" + layer?.field.id}
                  attributes={{ stroke: layer?.color }}
                  bounds={layer?.bounds}
                >
                  <circle r="10%" cx="50%" cy="50%" fill={layer?.color} />
                </SVGOverlay>
              )}
            </Fragment>
          ))}

      {markersWithNumber &&
        markersWithNumber.map((marker) => (
          <Marker
            key={marker.id}
            position={marker.position}
            icon={createCustomIcon({
              number: marker.iconNumber,
              color: marker.color,
            })}
            style={{ background: "trasparent", border: "none" }}
          >
            {marker.popup && (
              <Popup>
                <div
                  dangerouslySetInnerHTML={{
                    __html: sanitizedHTML(marker.popup),
                  }}
                ></div>
              </Popup>
            )}
            {marker.tooltip && (
              <Tooltip>
                <div
                  dangerouslySetInnerHTML={{
                    __html: sanitizedHTML(marker.tooltip),
                  }}
                ></div>
              </Tooltip>
            )}
            {marker.popupWithTable && (
              <Popup>
                <CustomizedTables
                  rows={marker.popupWithTable.rows}
                  tableTitle={marker.popupWithTable.tableTitle}
                ></CustomizedTables>
              </Popup>
            )}
            {marker.tooltipWithTable && (
              <Tooltip>
                <CustomizedTables
                  rows={marker.tooltipWithTable.rows}
                  tableTitle={marker.tooltipWithTable.tableTitle}
                ></CustomizedTables>
              </Tooltip>
            )}
          </Marker>
        ))}
    </MapContainer>
  );
};

export default MapComponent;
