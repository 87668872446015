const calculateAmount = (amount, irrigationAmount, irrigationNumber) => {
  return (parseFloat(amount) * irrigationAmount * irrigationNumber).toFixed(2);
};

const calculateAmountFromDripSubterranean = (
  waterDispersed,
  wettedLength,
  spaceBetween,
  amount
) => {
  if (!waterDispersed || !wettedLength || !spaceBetween || !amount) {
    throw new Error("Tutti i parametri devono essere validi e maggiori di 0.");
  }

  const cubicMeters =
    (waterDispersed * (wettedLength / (spaceBetween / 100)) * amount) / 1000;

  return cubicMeters.toFixed(3);
};

export const irrigationHandlers = (field) => ({
  sprinkler: (value) => {
    const irrigationAmount = field?.irrigations_system?.amount;
    const irrigationNumber = field?.irrigations_system?.number;
    return calculateAmount(value.amount, irrigationAmount, irrigationNumber);
  },
  drip: (value) => {
    const waterDispersed = field?.irrigations_system?.water_dispensed_bore;
    const wettedLength = field?.irrigations_system?.wetted_length;
    const spaceBetween = field?.irrigations_system?.space_driping_bores;
    return calculateAmountFromDripSubterranean(
      waterDispersed,
      wettedLength,
      spaceBetween,
      value.amount
    );
  },
  subterranean: (value) => {
    const waterDispersed = field?.irrigations_system?.water_dispensed_bore;
    const wettedLength = field?.irrigations_system?.wetted_length;
    const spaceBetween = field?.irrigations_system?.amount;
    return calculateAmountFromDripSubterranean(
      waterDispersed,
      wettedLength,
      spaceBetween,
      value.amount
    );
  },
});

export const calculateAmountFromTime = (hours, minutes, flowRate) => {
  const totalHours = hours + minutes / 60;
  return (totalHours * flowRate).toFixed(2);
};

export const calculateTimeFromAmount = (amount, flowRate) => {
  const totalHours = amount / flowRate;
  const hours = Math.floor(totalHours);
  const minutes = Math.round((totalHours - hours) * 60);
  return { hours, minutes };
};
