import L from "leaflet";
import "leaflet-draw/dist/leaflet.draw.css";
import "leaflet/dist/leaflet.css";
import React, { useEffect, useRef, useState } from "react";
import {
  FeatureGroup,
  LayersControl,
  MapContainer,
  Polygon,
  TileLayer,
} from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import { v4 as uuidv4 } from "uuid";
import { AddSearchControl } from "./addSearch.component";

const MapDrawComponent = (props) => {
  const mapRef = useRef(null);

  const mapHeight = props.mapHeight ?? "400px";
  const mapZoom = props.mapZoom ?? 7;
  const mapCenter = props.mapCenter ?? [40.356862371325384, 9.240084014976134];
  const mapUrl =
    props.mapUrl ?? "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
  const attribution =
    props.attribution ??
    '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>';
  const { polygons, onPolygonsChange } = props;
  const [isCreateButtonVisible, setIsCreateButtonVisible] = useState(
    polygons || polygons?.length <= 0
  );

  useEffect(() => {
    const map = mapRef.current;

    if (map) {
      if (map._layers) {
        const allLayerBounds = Object.values(map._layers).map(
          (layer) => layer._bounds
        );
        const allBounds = L.latLngBounds(allLayerBounds);
        if (allBounds.isValid()) {
          map.fitBounds(allBounds);
        } else {
          console.log("Nessun layer con bounds validi trovato.");
        }
      }
    }
  }, [mapRef.current, polygons]);

  const onEdited = (e) => {
    const editedPolygons = e.layers.getLayers().map((layer) => {
      const newCoordinates = layer
        .getLatLngs()[0]
        .map((latlng) => [latlng.lat, latlng.lng]);
      return {
        id: layer.options.id,
        position: newCoordinates,
        color: layer.options.color,
      };
    });

    onPolygonsChange(editedPolygons);
  };

  const onCreated = (e) => {
    const newCoordinates = e.layer
      .getLatLngs()[0]
      .map((latlng) => [latlng.lat, latlng.lng]);
    const newPolygons = {
      id: uuidv4(),
      position: newCoordinates,
      color: "blue",
    };
    onPolygonsChange([newPolygons]);
    setIsCreateButtonVisible(false);
  };

  const onDeleted = (e) => {
    onPolygonsChange([]);
    setIsCreateButtonVisible(true);
  };

  return (
    <MapContainer
      center={mapCenter}
      zoom={mapZoom}
      style={{ height: mapHeight }}
      ref={mapRef}
    >
      <LayersControl position="topright">
        <LayersControl.BaseLayer checked name="ArcGIS">
          <TileLayer
            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
            attribution='&copy; <a href="https://www.esri.com">Esri</a> | Abinsula'
          />
        </LayersControl.BaseLayer>

        <LayersControl.BaseLayer name="OpenStreetMap">
          <TileLayer url={mapUrl} attribution={attribution} />
        </LayersControl.BaseLayer>

        <LayersControl.BaseLayer name="Opentopomap">
          <TileLayer
            url="https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.opentopomap.org/copyright">OpenTopoMap</a> | Abinsula'
          />
        </LayersControl.BaseLayer>
      </LayersControl>

      <FeatureGroup>
        {polygons &&
          polygons.map((polygon) => (
            <Polygon
              key={polygon.id}
              id={polygon.id}
              positions={polygon.position}
              color={polygon.color}
            ></Polygon>
          ))}

        <EditControl
          position="topright"
          onEdited={onEdited}
          onCreated={onCreated}
          onDeleted={onDeleted}
          draw={{
            rectangle: false,
            circle: false,
            marker: false,
            polyline: false,
            circlemarker: false,
            polygon: isCreateButtonVisible
              ? {
                  allowIntersection: false,
                  drawError: {
                    color: "#e1e100",
                    message: "Do not cover the line",
                  },
                  shapeOptions: {
                    color: "#3388ff",
                  },
                }
              : false,
          }}
        />
      </FeatureGroup>
      <AddSearchControl mapRef={mapRef} />
    </MapContainer>
  );
};

export default MapDrawComponent;
