import authService from "../auth.service";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const CHECK_TOKEN_REQUEST = "CHECK_TOKEN_REQUEST";
export const LOGOUT = "LOGOUT";

export const loginRequest = (data) => ({
  type: LOGIN_REQUEST,
  payload: data,
});

export const loginSuccess = (data) => {
  return {
    type: LOGIN_SUCCESS,
    payload: data,
  };
};

export const loginFailure = (error) => {
  return {
    type: LOGIN_FAILURE,
    error: error,
    payload: null,
  };
};

export const logout = () => ({
  type: LOGOUT,
});

const logoutSuccess = (data) => {
  return {
    type: LOGOUT_SUCCESS,
    payload: null,
  };
};

export const logOutUser = () => {
  return async (dispatch) => {
    try {
      const response = await authService.logout();
      dispatch(logoutSuccess());
    } catch (error) {
      console.log(error);
    }
  };
};

export const checkTokenRequest = (data) => {
  return {
    type: CHECK_TOKEN_REQUEST,
    payload: data,
  };
};
