import { useDispatch, useSelector } from "react-redux";
import {
  fetchFieldsDataRequest,
  fetchFieldsDataSuccess,
} from "../store/actions/fields.actions";
import { useEffect } from "react";
import {
  fetchWeatherForecastDataRequest,
  fetchWeatherForecastDataSuccess,
} from "shared-components/src/components/weatherForecast/store/actions/weatherForecast.actions";
import {
  fetchPlanningDataRequest,
  fetchPlanningDataSuccess,
} from "../store/actions/planning.actions";
import {
  fetchOveviewDataRequest,
  fetchOveviewDataSuccess,
} from "../store/actions/oveview.actions";
import forest from "../components/map/icons/baseline_forest_black_24dp.png";
import yard from "../components/map/icons/baseline_yard_black_24dp.png";

const SetDummyData = (props) => {
  const dispatch = useDispatch();

  const fields = [
    {
      id: "550e8400-e29b-41d4-a716-446655440001",
      name: "Field 1",
      polygons: [
        {
          id: "550e8400-e29b-41d4-a716-446655440002",
          position: [
            [40.1631329, 8.8642502],
            [40.166806, 8.9641571],
            [40.0849013, 8.9590073],
            [40.077546, 8.8618469],
            [40.1631329, 8.8642502],
          ],
          color: "green",
          popup: null,
          tooltip: null,
        },
      ],
      irrigations_system: {
        system: "sprinkler",
        number: "2",
        amount: "222",
      },
      soil_type: {
        type: "texture",
        clay: "34",
        silt: "35",
        sand: "36",
      },
      crop: {
        type: "annual",
        species: "tomato",
        variety_name: "tomato name",
        variety_code: "code",
        distance_line: "40",
        distance_individuals: "20",
        planting_date: "28/08/2023",
        harvest_date: "28/08/2023",
        crop_yields: "1200",
        flowering_date: "28/08/2023",
        rieping_date: "28/08/2023",
      },
      sensors: [
        {
          id: "550e8400-e29b-41d4-a716-446655440003",
          position: [40.12306784061817, 8.888230363952015],
          name: "Soil moisture sensor",
          type: "soil_moisture_sensor",
          eui: "2CF7F12032400040",
        },
        {
          id: "550e8400-e29b-41d4-a716-446655440004",
          position: [40.151332420654455, 8.946085860250054],
          name: "Water counter",
          type: "water_counter",
          eui: "2CF7F12032400022",
        },
        {
          id: "550e8400-e29b-41d4-a716-446655440005",
          position: [40.07922477349853, 8.878918406819578],
          name: "Tree sensor (Nature 4.0)",
          type: "tree_sensor",
          eui: "2CF7F12032400033",
        },
      ],
      activities: {
        irrigation: [
          {
            date: "28/08/2023",
            time: "05:22",
            amount: "m^3",
          },
        ],
        soil_preparation: [
          {
            tillage: "28/08/2023",
            plough: "28/08/2023",
          },
        ],
        fertilizer: [
          {
            type: "type",
            date: "28/08/2023",
            quantity: "kg/ha",
          },
        ],
        pesticide: [
          {
            type: "type",
            date: "28/08/2023",
            quantity: "kg/ha",
          },
        ],
      },
      realtime: {
        irrigation_profile: {
          labels: [
            "30-June",
            "01-July",
            "02-July",
            "03-July",
            "04-July",
            "05-July",
            "06-July",
            "07-July",
          ],
          datasets: [
            {
              label: "Acqua erogata",
              data: [1450, 0, 0, 1200, 0, 0, 1250],
              backgroundColor: "rgba(53, 162, 235, 0.5)",
            },
            {
              label: "Acqua percolata",
              data: [230, 0, 0, 0, 0, 0, 50],
              backgroundColor: "rgba(255, 99, 132, 0.5)",
            },
          ],
        },
        transpiration_profile: {
          labels: ["0", "2", "4", "6", "8", "10"],
          datasets: [
            {
              label: "transpiration 1",
              data: [100, 400, 800, 1600],
              borderColor: "rgb(53, 162, 235)",
              backgroundColor: "rgba(53, 162, 235, 0.5)",
            },
            {
              label: "transpiration 2",
              data: [100, 200, 400, 800, 1600],
              borderColor: "rgb(255, 99, 132)",
              backgroundColor: "rgba(255, 99, 132, 0.5)",
            },
          ],
        },
        suggested_irrigation: {
          quantity: "100",
          optimal: "10/09/2023",
          risk: "15/09/2023",
          late: "20/09/2023",
        },
        sensors_data: [
          {
            id: "550e8400-e29b-41d4-a716-446655440003",
            sensor_name: "Soil moisture sensor",
            position: [40.12306784061817, 8.888230363952015],
            data: [
              { label: "Soil moisture", value: "%" },
              { label: "Soil Temperature", value: "°C" },
              { label: "Electrical conductivity", value: "ds / m" },
            ],
          },
          {
            id: "550e8400-e29b-41d4-a716-446655440004",
            sensor_name: "Water counter",
            position: [40.151332420654455, 8.946085860250054],
            data: [
              { label: "Total volume", value: "m^3" },
              { label: "Flow rate", value: "m^3/h" },
              { label: "Water temperature", value: "°C" },
              { label: "Last applied water amount", value: "m^3/h" },
              { label: "Last irrigation time", value: "hh:mm:ss" },
              { label: "Irrigation status", value: "Active / Inactive" },
            ],
          },
          {
            id: "550e8400-e29b-41d4-a716-446655440005",
            sensor_name: "Tree sensor (Nature 4.0)",
            position: [40.07922477349853, 8.878918406819578],
            data: [
              { label: "Value name", value: "value" },
              { label: "Value name 2", value: "value" },
            ],
          },
        ],
      },
    },
    {
      id: "field-2",
      name: "Field 2",
      polygons: [],
      irrigations_system: {},
      soil_type: {},
      crop: {},
      sensors: [],
      activities: {
        irrigation: [],
        soil_preparation: [],
        fertilizer: [],
        pesticide: [],
      },
      realtime: {
        irrigation_profile: {},
        suggested_irrigation: {},
      },
    },
  ];
  const weatherForecast = {
    data: {
      weather: "cloudy",
      temperatureMax: "18",
      temperatureMin: "23",
      humidity: "62",
      rain: "0",
      wind: "14/16",
    },
    six_mount_data: {
      labels: ["0", "2", "4", "6", "8"],
      datasets: [
        {
          label: "Curva ipsografica",
          data: [195, 165, 155, 145, 115],
          borderColor: "rgb(53, 162, 235)",
          backgroundColor: "rgba(53, 162, 235, 0.5)",
        },
        {
          label: "Altezza media",
          data: [153, 154, 155, 156, 157],
          borderColor: "rgb(255, 99, 132)",
          backgroundColor: "rgba(255, 99, 132, 0.5)",
        },
      ],
    },
  };

  const planning = [
    {
      id: "1",
      fieldId: "550e8400-e29b-41d4-a716-446655440001",
      fieldName: "Field 1",
      plannedIrrigation: "27/09/2023",
    },
    {
      id: "2",
      fieldId: "field-2",
      fieldName: "Field 2",
      plannedIrrigation: "24/10/2023",
    },
  ]; // motrare per ordine di data yeaaaa!

  const oveview = [
    {
      id: 10,
      bounds: [
        [40.72449444341235, 8.557504075530062],
        [40.72472210842736, 8.557884829425994],
        [40.725039211971925, 8.558801856414858],
        [40.724140748004956, 8.560008470873885],
        [40.723478072239324, 8.559086081154122],
      ],
      image: "yard",
      imageUrl: yard,
      color: "#4674c2",
    },
    {
      id: 11,
      bounds: [
        [40.7251775631652, 8.563362441231371],
        [40.7246246648472, 8.56393089070986],
        [40.72507186237128, 8.564681673039926],
        [40.72561662620448, 8.564048870790286],
      ],
      image: "forest",
      imageUrl: forest,
      color: "#ea7c3c",
    },
  ];

  const setDummyFields = () => {
    // dispatch(fetchFieldsDataRequest());
    // dispatch(fetchFieldsDataSuccess(fields));
    dispatch(fetchWeatherForecastDataRequest());
    dispatch(fetchWeatherForecastDataSuccess(weatherForecast));
    dispatch(fetchPlanningDataRequest());
    dispatch(fetchPlanningDataSuccess(planning));
    // dispatch(fetchOveviewDataRequest());
    // dispatch(fetchOveviewDataSuccess(oveview));
  };

  // useEffect(() => {
  //     setDummyFields();
  // }, []);

  // const state = useSelector(state => state);
  // console.log('state', state);

  return;
};

export default SetDummyData;
