import { InfoOutlined } from "@mui/icons-material";
import { Tooltip } from "@mui/material";

const CustomTooltip = (props) => {
  const title = props?.title;

  return (
    <Tooltip title={title}>
      <InfoOutlined style={{ width: "20px", height: "20px" }} />
    </Tooltip>
  );
};

export default CustomTooltip;
