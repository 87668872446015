import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  actionType,
  irrigationTypeEnum,
  sensorStatus,
} from "../../config/reatimeValues";
import {
  addCommandRequest,
  fetchCommandStatusRequest,
} from "../../store/actions/sensors.actions";
import dayjs from "dayjs";
import {
  calculateAmountFromTime,
  calculateTimeFromAmount,
} from "../../utils/irrigationHandlers";

const IrrigationActionsForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isMobile = useMediaQuery("(max-width: 600px");
  const [irrigationType, setIrrigationType] = useState(
    irrigationTypeEnum.customAmount
  );
  const [customAmount, setCustomAmount] = useState(0);
  const [customHours, setCustomHours] = useState(0);
  const [customMinutes, setCustomMinutes] = useState(0);
  const commandStatus = useSelector((state) => state.sensors.commandstatus);
  const [status, setStatus] = useState(undefined);
  const [isIrrigationField, setIsIrrigationField] = useState(false);
  const [irrigationSensor, setIrrigationSensor] = useState("");
  const [isChangedField, setIsChangedField] = useState(false);
  const [error, setError] = useState("");

  const device_id = irrigationSensor?.name;
  const platformAmount = 50;

  const fieldSelected = useSelector((state) => state.field.data);

  const field = useSelector((state) => state.fields.data).find(
    (f) => f.id === fieldSelected
  );
  const prevField = useRef(null);

  useEffect(() => {
    if (field !== prevField) {
      prevField.current = field;
      setIsChangedField(true);
    }
  }, [field]);

  useEffect(() => {
    const pumpSensor = field.sensors.find(
      (item) => item?.type === "pump_valve_controller"
    );
    const soilSensor = field.sensors.find(
      (item) => item?.type === "soil_moisture_sensor"
    );
    setIrrigationSensor(pumpSensor);
    setIsIrrigationField(!!pumpSensor && !!soilSensor);
  }, [field]);

  const handleIrrigationChange = (event) => {
    setIrrigationType(event.target.value);
  };

  const flowRate = 10;

  const handleCustomAmountChange = (event) => {
    const inputValue = event.target.value;

    if (inputValue === "" || /^\d*\.?\d+$/.test(inputValue)) {
      setCustomAmount(inputValue);
      setError("");
      if (inputValue) {
        const { hours, minutes } = calculateTimeFromAmount(
          inputValue,
          flowRate
        );
        setCustomHours(hours);
        setCustomMinutes(minutes);
      } else {
        setCustomHours(0);
        setCustomMinutes(0);
      }
    } else {
      setError("Invalid value");
    }
  };

  const handleCustomHoursChange = (event) => {
    const inputValue = parseInt(event.target.value, 10);
    if (!isNaN(inputValue) && inputValue >= 0) {
      setCustomHours(inputValue);

      const newAmount = calculateAmountFromTime(
        inputValue,
        customMinutes,
        flowRate
      );
      setCustomAmount(newAmount);
    }
  };

  const handleCustomMinutesChange = (event) => {
    const inputValue = parseInt(event.target.value, 10);
    if (!isNaN(inputValue) && inputValue >= 0 && inputValue < 60) {
      setCustomMinutes(inputValue);
      const newAmount = calculateAmountFromTime(
        customHours,
        inputValue,
        flowRate
      );
      setCustomAmount(newAmount);
    }
  };

  const getFormatedDate = (value) => {
    return dayjs(value).format("DD/MM/YYYY");
  };

  const onSubmit = (values) => {
    const body = {
      unit: irrigationType === irrigationTypeEnum.customAmount ? "m^3" : "m^3",
      sensor: device_id,
      field: field.name,
      actionType: actionType.irrigate,
      quantity:
        irrigationType === irrigationTypeEnum.customAmount
          ? Number(customAmount)
          : platformAmount,
    };
    dispatch(addCommandRequest(body));
  };

  useEffect(() => {
    if (commandStatus?.[0]) {
      if (commandStatus[0].attributes.status !== "error") {
        setStatus(commandStatus[0].attributes);
      } else {
        setStatus(commandStatus[1].attributes);
      }
    }
  }, [commandStatus]);

  useEffect(() => {
    if (isChangedField) {
      const queryParams = { fieldId: field.name, sensorId: device_id };
      dispatch(fetchCommandStatusRequest(queryParams));
    }
    if (field?.name && device_id) {
      const queryParams = { fieldId: field.name, sensorId: device_id };
      const intervalId = setInterval(() => {
        dispatch(fetchCommandStatusRequest(queryParams));
      }, 30000);
      return () => clearInterval(intervalId);
    }
  }, [field, device_id, isChangedField]);

  function getStatusInfo(status) {
    switch (status?.status) {
      case sensorStatus.ready:
        return {
          label: t("irrigation_actions.statusInfo.label_ready"),
          color: "gray",
          message: `${t(
            "irrigation_actions.statusInfo.message_ready"
          )} ${getFormatedDate(status.updatedAt)} - ${status?.quantity} ${
            status?.unit
          }`,
        };
      case sensorStatus.executed:
        return {
          label: t("irrigation_actions.statusInfo.label_executed"),
          color: "#e97132",
          message: t("irrigation_actions.statusInfo.message_executed"),
        };
      case sensorStatus.wait:
        return {
          label: `${t("irrigation_actions.statusInfo.label_wait")} - ${
            status?.quantity
          } ${status?.unit}`,
          color: "#04b1f1",
          message: "",
        };
      default:
        return {
          label: "Unknown status",
          color: "gray",
          message: "Status not recognized",
        };
    }
  }

  return (
    <>
      {isIrrigationField ? (
        <Box
          p={2}
          mt={2}
          display="flex"
          flexDirection="column"
          gap={1}
          backgroundColor="#f5f5f5"
        >
          <FormControl>
            <FormLabel
              id="radio-buttons-group-label"
              sx={{ fontWeight: "bold", color: "black" }}
            >
              {t("irrigation_actions.title")}
            </FormLabel>
            <Box
              p={1}
              borderRadius={1}
              backgroundColor="white"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              flexDirection={isMobile ? "column" : "row"}
              gap={1}
              marginTop={2}
              height="100%"
            >
              <RadioGroup
                aria-labelledby="radio-buttons-group-label"
                value={irrigationType}
                onChange={handleIrrigationChange}
                name="radio-buttons-group"
              >
                <FormControlLabel
                  value={irrigationTypeEnum.trustedYou}
                  control={<Radio />}
                  label={t("irrigation_actions.statusInfo.trustedYou")}
                />
                <Grid container alignItems="center">
                  <Grid item>
                    <FormControlLabel
                      value={irrigationTypeEnum.customAmount}
                      control={<Radio />}
                      label={t("irrigation_actions.statusInfo.definedAmount")}
                    />
                  </Grid>
                  <Grid item>
                    {irrigationType === irrigationTypeEnum.customAmount && (
                      <Box display="flex" alignItems="center" gap={2}>
                        <TextField
                          label={t(
                            "irrigation_actions.statusInfo.label_liters"
                          )}
                          value={customAmount}
                          onChange={handleCustomAmountChange}
                          error={!!error}
                          helperText={error}
                          sx={{ width: "120px" }}
                        />
                        <TextField
                          label="Hours"
                          type="number"
                          value={customHours}
                          onChange={handleCustomHoursChange}
                          sx={{ width: "80px" }}
                        />
                        <TextField
                          label="Minutes"
                          type="number"
                          value={customMinutes}
                          onChange={handleCustomMinutesChange}
                          sx={{ width: "80px" }}
                        />
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </RadioGroup>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                gap={2}
                p={1}
                height="140px"
                width="500px"
              >
                <Button
                  variant="contained"
                  sx={{
                    width: "fit-content",
                    height: "40px",
                    backgroundColor: "#156082",
                    textTransform: "none",
                  }}
                  onClick={onSubmit}
                  disabled={
                    (status?.status !== sensorStatus.ready &&
                      status?.status !== sensorStatus.error) ||
                    error
                  }
                >
                  {t("irrigation_actions.buttonLabel")}
                </Button>
                <Stack
                  spacing={2}
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  marginBottom={3}
                >
                  <Stack direction="column">
                    {getStatusInfo(status) === "wait" ? (
                      <Typography variant="body2">
                        {getStatusInfo(status).label} {customAmount} m^3
                      </Typography>
                    ) : (
                      <Typography>{getStatusInfo(status).label}</Typography>
                    )}
                    <Typography color="gray" variant="body2">
                      {getStatusInfo(status).message}
                    </Typography>
                  </Stack>
                  <Box
                    borderRadius={100}
                    border="1.5px solid black"
                    bgcolor={getStatusInfo(status).color}
                    height={20}
                    width={20}
                  />
                </Stack>
              </Box>
            </Box>
          </FormControl>
        </Box>
      ) : undefined}
    </>
  );
};

export default IrrigationActionsForm;
