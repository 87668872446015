import { Button, Grid, Paper, Typography, useMediaQuery } from "@mui/material";
import { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomizedTables, {
  createData,
} from "shared-components/src/components/displayData/CustomizedTables.component";
import FieldSelectorComponent from "../components/fieldSelector/FieldSelector.component";
import MapFielComponent from "../components/map/MapField.component";
import { routerPaths } from "../router/routerPaths";
import {
  deleteActivityState,
  fetchActivityRequest,
} from "../store/actions/activities.actions";
import ListTables from "shared-components/src/components/displayData/ListTables.component";
import {
  cropAnnualKeys,
  cropPerennialKeys,
  cropType,
  soilTypeKeys,
  irrigationSystem,
  irrigationsSystemSprinklerKeys,
  irrigationsSystemDriprKeys,
  irrigationsSystemSubterraneanKeys,
  irrigationsSystemKeys,
} from "../config/fieldIntialValues";
import { sortIrrigationsByDateAndTime } from "../utils/sortIrrigations";

const FieldsPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width: 767px");

  const fieldSelected = useSelector((state) => state?.field?.data);
  const fields = Array.from(useSelector((state) => state?.fields?.data));
  let field = fields?.find((f) => f.id === fieldSelected);
  const activities = useSelector((state) => state?.activities?.data);
  const irrigations = activities?.attributes?.last_irrigations?.data?.map(
    (irrigation) => ({
      amount: `${irrigation?.attributes?.amount} m^3`,
      date: irrigation?.attributes?.date,
      time: irrigation?.attributes?.time,
    })
  );
  const soil_preparations =
    activities?.attributes?.soil_preparations?.data?.map(
      (soil_preparation) => ({
        type: soil_preparation?.attributes?.type,
        date: soil_preparation?.attributes?.date,
        depth: `${soil_preparation?.attributes?.depth} cm`,
      })
    );
  const pesticides = activities?.attributes?.pesticides?.data?.map(
    (pesticide) => ({
      type: pesticide?.attributes?.type,
      date: pesticide?.attributes?.date,
      quantity: `${pesticide?.attributes?.quantity} kg/ha`,
    })
  );
  const fertilizers = activities?.attributes?.fertilizers?.data?.map(
    (fertilizer) => ({
      type: fertilizer?.attributes?.type,
      date: fertilizer?.attributes?.date,
      quantity: `${fertilizer?.attributes?.quantity} kg/ha`,
    })
  );

  const createRowIrrigationSystemData = (data) => {
    const system = data?.["system"];
    let orderKeys;
    switch (system) {
      case irrigationSystem.sprinkler:
        orderKeys = irrigationsSystemSprinklerKeys;
        break;
      case irrigationSystem.drip:
        orderKeys = irrigationsSystemDriprKeys;
        break;
      case irrigationSystem.subterranean:
        orderKeys = irrigationsSystemSubterraneanKeys;
        break;
      default:
        orderKeys = irrigationsSystemKeys;
        break;
    }

    const result = orderKeys.map((key) => {
      const label = `${t("irrigation_system." + key)}`;
      return createData(label, data[key]);
    });
    return result;
  };

  const createRowCropData = (data) => {
    const type = data?.["type"];
    const orderKeys =
      type === cropType.perennial ? cropPerennialKeys : cropAnnualKeys;

    const result = orderKeys.map((key) => {
      const label = `${t("crop_details." + key)}`;
      return createData(label, data[key]);
    });
    return result;
  };

  const createRowSoilTypeDataObject = (data) => {
    const result = soilTypeKeys.map((key) => {
      const label = `${t("soilType." + key)} ${t("soilType.unit." + key)}`;
      return createData(label, data[key]);
    });
    return result;
  };

  useEffect(() => {
    const activity = field?.activity?.id;
    if (activity) {
      dispatch(fetchActivityRequest(activity));
    } else {
      dispatch(deleteActivityState(activity));
    }
  }, [field]);

  return (
    <Fragment>
      <h3>{t("fields-page.title")}</h3>
      <Grid
        container
        rowSpacing={2}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        sx={{ marginBottom: "20px" }}
      >
        {isMobile ? null : (
          <Grid item xs={12} sm={9} md={9}>
            <Typography>
              <span>{t("fields-page.subtitle")}</span>
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} sm={3} md={3}>
          <Button
            variant="contained"
            onClick={() => navigate(routerPaths.field_add)}
          >
            {t("fields-page.button-add-field")}
          </Button>
        </Grid>
      </Grid>
      <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12}>
          <Paper variant="outlined" sx={{ height: "100%", padding: "20px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>
                <FieldSelectorComponent></FieldSelectorComponent>
              </div>
              {fieldSelected && (
                <div>
                  <Button
                    variant="contained"
                    onClick={() => navigate(routerPaths.field_update)}
                  >
                    {t("fields-page.button-update-field")}
                  </Button>
                </div>
              )}
            </div>
          </Paper>
        </Grid>
        {fieldSelected && (
          <>
            <Grid item xs={12}>
              <Paper
                variant="outlined"
                sx={{ height: "100%", padding: "20px" }}
              >
                <MapFielComponent></MapFielComponent>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper
                variant="outlined"
                sx={{ height: "100%", paddingX: "20px", paddingBottom: "20px" }}
              >
                <div>
                  <h4>{t("fields-page.last-activities")}</h4>
                </div>
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid item xs={12} sm={6} md={3}>
                    <ListTables
                      rows={sortIrrigationsByDateAndTime(irrigations)}
                      tableTitle={`${t("fields-page.irrigation")}`}
                    ></ListTables>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <ListTables
                      rows={soil_preparations}
                      tableTitle={`${t("fields-page.soil-preparation")}`}
                    ></ListTables>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <ListTables
                      rows={fertilizers}
                      tableTitle={`${t("fields-page.fertilizer")}`}
                    ></ListTables>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <ListTables
                      rows={pesticides}
                      tableTitle={`${t("fields-page.pesticide")}`}
                    ></ListTables>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper
                variant="outlined"
                sx={{ height: "100%", paddingX: "20px", paddingBottom: "20px" }}
              >
                <div>
                  <h4>{t("fields-page.general-info")}</h4>
                </div>
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid item xs={12} sm={6} md={4}>
                    <CustomizedTables
                      rows={createRowIrrigationSystemData(
                        field?.irrigations_system
                      )}
                      tableTitle={t("fields-page.irrigation-system")}
                    ></CustomizedTables>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <CustomizedTables
                      rows={createRowSoilTypeDataObject(field?.soil_type)}
                      tableTitle={t("fields-page.soil-type")}
                    ></CustomizedTables>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <CustomizedTables
                      rows={createRowCropData(field?.crop)}
                      tableTitle={t("fields-page.crope")}
                    ></CustomizedTables>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </>
        )}
      </Grid>
    </Fragment>
  );
};

export default FieldsPage;
