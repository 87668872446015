import {
  Alert,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  hideProgress,
  showProgress,
} from "shared-components/src/components/feedback/store/circularProgress.actions";
import * as Yup from "yup";
import CircularProgressComponent from "../feedback/CircularProgress.component";
import { loginRequest, logout } from "./store/auth.actions";
import AlertInfoComponent from "../feedback/AlertInfo.component";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const LoginComponent = (props) => {
  const { logo, onLoginHandler } = props;
  const token = useSelector((state) => state.auth.token);
  const error = useSelector((state) => state.auth.error);
  const [showPassword, setShowPassword] = useState(false);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleLogin = (value) => {
    dispatch(showProgress());
    dispatch(loginRequest(value));
  };

  useEffect(() => {
    dispatch(logout());
  }, []);

  useEffect(() => {
    if (token) {
      dispatch(hideProgress());
      onLoginHandler();
    }
  }, [token]);

  const initialValues = {
    username: "",
    password: "",
    remember: false,
  };

  const validationSchema = Yup.object({
    username: Yup.string().required("Required"),
    password: Yup.string().required("Required"),
  });

  return (
    <Fragment>
      <AlertInfoComponent />
      <CircularProgressComponent />
      <Grid container rowSpacing={2} columnSpacing={2}>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          {logo && <img src={logo} style={{ maxWidth: "inherit" }} alt="" />}
          <h4>{t("login")}</h4>
        </Grid>

        <Grid item xs={12}>
          {error && ( // Mostra il messaggio di errore se presente
            <Alert severity="error" style={{ marginBottom: "16px" }}>
              {t("login_error")}
            </Alert>
          )}
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleLogin}
          >
            <Form>
              <Grid container rowSpacing={2} columnSpacing={2} style={{}}>
                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    label={t("username")}
                    name={`username`}
                    variant="outlined"
                    fullWidth
                  />
                  <ErrorMessage name={`username`} component="div" />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    type={showPassword ? "text" : "password"}
                    as={TextField}
                    label={t("password")}
                    name={`password`}
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      ),
                    }}
                  />
                  <ErrorMessage name={`password`} component="div" />
                </Grid>
                <Grid item xs={12}>
                  <Field type="checkbox" name="remember">
                    {({ field }) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            {...field}
                            checked={field.value}
                            color="primary"
                          />
                        }
                        label={t("remember_me")}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                  >
                    {t("login")}
                  </Button>
                </Grid>
                {/* <Grid item xs={12} sm={12} md={12}>
                  <div style={{ fontSize: "12px" }}>
                    {t("not_registered")}
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <div style={{ fontSize: "12px" }}>
                    {t("forgot_pwd")}
                  </div>
                </Grid> */}
              </Grid>
            </Form>
          </Formik>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default LoginComponent;
